@import './reset.css';
@import 'xterm/css/xterm.css';

body {
  font-family: monospace;
  background-color: #dadac5;
  color: #212121;
  margin: 0;
  padding: 0;

  transition: background-color 0.5s ease, color 0.5s ease;

  &.lights-off {
    color: #616161;
    background-color: #1d1d1d;
  }

  &.cursor-pointer {
    cursor: pointer;
  }

  &.centered {
    display: flex;
    align-items: center;
    max-height: 100vh;
  }
}

#crt {
  margin: 20px 0;
  width: 100%;
  height: 1000px;
  max-height: 80vw;
}

#hidden {
  position: fixed;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  overflow: hidden;

  #terminal {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }
}

img.logo {
  width: 100%;
  height: auto;
}

section,
footer,
small {
  margin: 3em auto 0 auto;
  display: block;
  text-align: center;
  font-size: 1.2em;
  padding: 0 50px;
}

footer {
  font-size: 0.8em;
}

small {
  font-size: 0.8em;
  opacity: 0.5;
  margin-top: 1em;
  margin-bottom: 5em;
}

a {
  color: inherit;
  text-decoration: none;
}

section.long-form {
  font-size: 1em;
  max-width: 600px;
  text-align: justify;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2em 0 0.5em 0;
  }

  p {
    margin: 0 0 1em 0;
  }

  li {
    margin: 0 0 0.5em 0;
  }

  a {
    text-decoration: underline;
  }
}

section.store {
  padding: 0;

  .logo {
    width: auto;
    height: auto;
    max-width: 80vw;
    max-height: 50px;
  }

  .copy {
    line-height: normal;
    max-width: 500px;
    margin: 50px auto;
    padding: 0 50px;
    font-size: 0.9em;
  }

  .shirt {
    margin: 10px auto;
    position: relative;
    padding-top: 73.7704918033%; // 976 × 720 = ((720/976) * 100)
    width: 80vw;
    background: center center no-repeat transparent;
    background-size: contain;
    transition: background-image 0.25s ease;

    &[data-color='black'][data-side='back'] {
      background-image: url('/assets/img/store/black-back.png');
    }

    &[data-color='black'][data-side='front'] {
      background-image: url('/assets/img/store/black-front.png');
    }

    &[data-color='white'][data-side='back'] {
      background-image: url('/assets/img/store/white-back.png');
    }

    &[data-color='white'][data-side='front'] {
      background-image: url('/assets/img/store/white-front.png');
    }
  }

  form {
    max-width: 350px;
    margin: 0 auto 50px auto;
  }

  .toggle {
    display: flex;
    border: 2px solid #212121;
    background: transparent;
    position: relative;

    .title {
      position: absolute;
      top: -25px;
      left: -2px;
      font-size: 0.9em;
    }

    &.side {
      max-width: 150px;
      margin: 0 auto;
    }

    input[type='radio'] {
      display: none;
    }

    .option {
      display: block;
      flex-grow: 1;
      border-right: 2px solid #212121;
      cursor: pointer;
      transition: background-color 0.25s ease, color 0.25s ease;

      &:last-child {
        border-right: none;
      }

      .mobile {
        display: none;
      }
    }

    input[type='radio']:checked + .option {
      background: #212121;
      color: #dadac5;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .button,
    .toggle {
      flex-grow: 1;
      margin: 0 25px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .button {
    background: #212121;
    border: none;
    color: #dadac5;
    cursor: pointer;
  }

  .button,
  .toggle .option {
    text-transform: uppercase;
    padding: 5px;
    margin: 0;
  }
}

@media only screen and (max-width: 600px) {
  section.store {
    .shirt {
      margin-top: 50px;
      width: 90vw;
    }

    .toggle {
      .option {
        .desktop {
          display: none;
        }

        .mobile {
          display: unset;
        }
      }
    }
  }
}
